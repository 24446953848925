import React, { useEffect, useState } from 'react';
import styles from './Verification.module.css';
import { RoundLayout } from 'shopify/layouts/RoundLayout/RoundLayout';
import { Logo } from 'shopify/components/Logo/Logo';
import { Title } from 'shopify/components/Title/Title';
import { TextSecondary } from 'shopify/components/TextSecondary/TextSecondary';
import { CodeVerification } from 'shopify/modules/CodeVerification/CodeVerification';
import { Button } from 'shopify/components/Button/Button';
import { Countdown } from 'shopify/modules/Countdown/Countdown';
import { NavLinkWithIcon } from 'shopify/components/NavLinkWithIcon/NavLinkWithIcon';
import { LeftDirectionArrow } from 'shopify/icons/LeftDirectionArrow';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CommonMethods } from 'lib/CommonMethods';
import { useToast } from 'hooks/useToast';
export const Verification = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { addToast } = useToast();

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [resetCountdown, setResetCountdown] = useState(false);
    const [registerFormData, setRegisterFormData] = useState(null);
    const [codeVerificationError, setCodeVerificationError] = useState(null);
    const onCountdownEnd = () => setIsButtonDisabled(false);
    const onResetComplete = () => setResetCountdown(false);

    const getUrlParams = () => {
        const urlParams = new URLSearchParams(location.search);
        const userId = urlParams.get('user_id');
        const username = urlParams.get('username');

        if (!userId || !username) {
            navigate('/');
            return;
        }

        return { userId, username };
    };

    const clearCodeVerificationError = () => setCodeVerificationError(null);

    const sendVerificationCode = () => {
        const urlParamsData = getUrlParams();

        axios
            .request({
                url: CommonMethods.replaceUrlEndpoint(`verification_code/${urlParamsData.userId}`),
                method: 'GET',
                config: { headers: { 'Content-Type': 'application/json' } },
            })
            .then((response) => {
                if (response.data) {
                    addToast('New verification code has been sent to your email');
                }
            })
            .catch(() => {
                addToast('Something wrong with resend new verification code service', 'warning');
            });
    };

    const checkVerificationCode = code => {
        const urlParamsData = getUrlParams();

        axios.request({
            url: CommonMethods.replaceUrlEndpoint(`verification_code/${urlParamsData.userId}`),
            method: 'POST',
            config: { headers: { 'Content-Type': 'application/json' } },
            data: {
                user_id: urlParamsData.userId,
                code: code
            }
        })
            .then(({ data }) => {
                if ('is_verified' in data) {
                    if (data.is_verified && data.subscription) {
                        const { confirmation_url } = data.subscription;

                        localStorage.removeItem('verificationCodeTimestampCountdown');

                        window.location.href = confirmation_url;
                    }
                }
            })
            .catch(({ response }) => {
                setCodeVerificationError({ message: response.data.message });
            })
    };

    const onClickResendButton = () => {
        // Устанавливаем новое время окончания таймера и блокируем кнопку
        const now = new Date().getTime();
        const newEndTime = now + 3 * 60 * 1000;
        localStorage.setItem('verificationCodeTimestampCountdown', newEndTime);
        setIsButtonDisabled(true);
        setResetCountdown(true); // Запускаем таймер заново

        sendVerificationCode();
    };

    useEffect(() => {
        try {
            const urlParamsData = getUrlParams();
            const countdown = localStorage.getItem('verificationCodeTimestampCountdown');

            setRegisterFormData(urlParamsData);

            if (!countdown) {
                sendVerificationCode();
            }
        } catch (error) {
            console.error('Invalid or corrupted data parameter:', error);
            navigate('/');
        }
    }, [location, navigate]); //eslint-disable-line

    return (
        registerFormData
            ? <RoundLayout>
                <React.Fragment>
                    <Logo/>

                    <div className={styles.title}>
                        <Title
                            text='Check your email for a code'
                        />

                        <TextSecondary
                            className={styles.text}
                            text={
                                <React.Fragment>
                                    We’ve sent a 6-character code to{' '}
                                    <span>{registerFormData.username}</span>{' '}
                                    The code expires in 30 minutes, so please enter it soon.
                                    Can’t find your code? Check your spam folder
                                </React.Fragment>
                            }
                        />
                    </div>

                    <CodeVerification
                        callback={checkVerificationCode}
                        error={codeVerificationError}
                        clearCodeVerificationErrorCallback={clearCodeVerificationError}
                    />

                    <Button
                        text='Send code'
                        onClick={onClickResendButton}
                        className={styles.button}
                        disabled={isButtonDisabled}
                    />

                    <Countdown
                        reset={resetCountdown}
                        onCountdownEnd={onCountdownEnd}
                        onResetComplete={onResetComplete} // Сбрасываем флаг после запуска таймера
                        localeStorageKey='verificationCodeTimestampCountdown'
                    />

                    <NavLinkWithIcon
                        icon={<LeftDirectionArrow/>}
                        text='Back to Sign in'
                        route='/sign-in'
                    />
                </React.Fragment>
            </RoundLayout>
            : null
    )
}