import React, { useEffect, useState } from 'react';
import styles from './EmailMasterCard.module.css';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { CommonMethods } from 'lib/CommonMethods';

const cx = classNames.bind(styles);
export const EmailMasterCard = ({ previewImageUrl, onClick, isSelected }) => {
    const [isImageExist, setIsImageExist] = useState(false);

    useEffect(() => {
        CommonMethods.isImageExist(previewImageUrl)
            .then(result => setIsImageExist(result));
    }, [previewImageUrl]);

    return (
        isImageExist
            ? <div className={cx(styles.emailMasterPreviewCardContainer, { 'selected': isSelected })} onClick={onClick}>
                <div
                    className={styles.emailMasterPreviewCard}
                    style={{ backgroundImage: `url(${previewImageUrl})` }}
                />
            </div>
            : <div className={cx(styles.emailMasterPreviewCardContainer, { 'selected': isSelected })} onClick={onClick}>
                <div className={styles.defaultMasterPreview}/>
            </div>
    )
};

EmailMasterCard.propTypes = {
    previewImageUrl: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isSelected: PropTypes.bool.isRequired
};