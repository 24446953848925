import React from 'react';
import styles from 'common/_CustomModal/CreateEmailTemplateModal/CreateEmailTemplateModal.module.css';
import PropTypes from 'prop-types';
import LightGreenButton from 'common/_Buttons/_GreenButtons/LightGreenButton';
import { CodeIcon } from 'assets/icons/CodeIcon';
import { BranchIcon } from 'assets/icons/BranchIcon';

export const CreateEmailTemplateModal = ({
    title,
    codeEditorTitle,
    codeEditorDesc,
    masterTitle,
    masterDesc,
    onClickDefaultTemplateButton,
    onClickMasterTemplateButton,
    cancelButtonTitle,
    onClickCancel
}) => {
    return (
        <div className={styles.container}>
            <h3>{title}</h3>

            <div className={styles.selectTemplateTypeButtons}>
                <button onClick={onClickDefaultTemplateButton}>
                    <div className={styles.icon}>
                        <CodeIcon/>
                    </div>
                    <h6>{codeEditorTitle}</h6>
                    <p>{codeEditorDesc}</p>
                </button>
                <span className={styles.line}/>
                <button onClick={onClickMasterTemplateButton}>
                    <div className={styles.icon}>
                        <BranchIcon/>
                    </div>
                    <h6>{masterTitle}</h6>
                    <p>{masterDesc}</p>
                </button>
            </div>
            <div className={styles.buttons}>
                <LightGreenButton
                    name={cancelButtonTitle}
                    onClick={onClickCancel}
                />
            </div>
        </div>
    )
};
CreateEmailTemplateModal.propTypes = {
    title: PropTypes.string.isRequired,
    codeEditorTitle: PropTypes.string.isRequired,
    codeEditorDesc: PropTypes.string.isRequired,
    masterTitle: PropTypes.string.isRequired,
    masterDesc: PropTypes.string.isRequired,
    onClickDefaultTemplateButton: PropTypes.func.isRequired,
    onClickMasterTemplateButton: PropTypes.func.isRequired,
    cancelButtonTitle: PropTypes.string.isRequired,
    onClickCancel: PropTypes.func.isRequired
};