import React, { useState } from 'react';
import styles from './SelectMasterTemplateModal.module.css';
import PropTypes from 'prop-types';
import LightGreenButton from 'common/_Buttons/_GreenButtons/LightGreenButton';
import GreenButton from 'common/_Buttons/_GreenButtons/GreenButton';
import { map, eq, find } from 'lodash';
import { EmailMasterCard } from 'components/Cards/CreativeCards/EmailMasterCard/EmailMasterCard';

export const SelectMasterTemplateModal = ({ title, desc, masters, cancelButtonTitle, acceptButtonTitle, onClickCancel, onClickAccept }) => {
    const [mastersList, setMastersList] = useState(map(masters, master => (
        { ...master, selected: false }
    )));

    const selectedMaster = find(mastersList, { selected: true });
    const onClickEmailMasterCard = id => {
        return () => setMastersList(prev => map(prev, master => {
            return eq(master.id, id)
                ? { ...master, selected: true }
                : { ...master, selected: false }
        }))
    };

    return (
        <div className={styles.container}>
            <h3>{title}</h3>
            <p>{desc}</p>

            <div className={styles.masterTemplatesList}>
                {map(mastersList, master =>
                        <EmailMasterCard
                            key={master.id}
                            previewImageUrl={master.previewImageUrl}
                            onClick={onClickEmailMasterCard(master.id)}
                            isSelected={master.selected}
                        />
                    )
                }
            </div>

            <div className={styles.buttons}>
                <LightGreenButton
                    name={cancelButtonTitle}
                    onClick={onClickCancel}
                />
                <GreenButton
                    name={acceptButtonTitle}
                    onClick={onClickAccept(selectedMaster)}
                />
            </div>
        </div>
    )
};

SelectMasterTemplateModal.propTypes = {
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    masters: PropTypes.array.isRequired,
    cancelButtonTitle: PropTypes.string.isRequired,
    acceptButtonTitle: PropTypes.string.isRequired,
    onClickCancel: PropTypes.func.isRequired,
    onClickAccept: PropTypes.func.isRequired
};