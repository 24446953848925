import { createSelector } from 'reselect';
import { isArray, isEmpty } from 'lodash';

const selectCurrentScenario = store => store.scenarios.currentScenario;
const selectCurrentEditTemplate = store => store.templates.currentEditTemplate;
const selectCurrentTemplateEmailsFolder = store => store.images.currentFolder;
const selectCurrentTemplateEmailsMaster = store => store.templates.currentEditTemplate;
const selectMetricsData = store => store.analytics.metricsViewData.metrics;
const selectCurrentEditSegment = store => store.segments.segment;
const selectCurrentEvent = store => store.integrations.currentEvent;
const selectConsentCategory = store => store.consentCategories.selectedConsentCategory;
const selectCurrentProvider = store => store.communications.currentProvider;

export const dynamicScenarioNameSelector = () => createSelector(
    [selectCurrentScenario],
    currentScenario => currentScenario.scenario_name
);
export const dynamicTemplateNameSelector = () => createSelector(
    [selectCurrentEditTemplate],
    currentTemplate => currentTemplate.template_name
);
export const dynamicAnalyticsDataSelector = () => createSelector(
    [selectMetricsData],
    metrics => isArray(metrics) && metrics.length
        ? metrics[0].metric_full_name
        : ''
);
export const dynamicSegmentNameSelector = () => createSelector(
    [selectCurrentEditSegment],
    currentSegment => !isEmpty(currentSegment)
        ? currentSegment.segment_name
        : 'Segment'
);
export const dynamicEventNameSelector = () => createSelector(
    [selectCurrentEvent],
    currentEvent => currentEvent.event_name
);
export const dynamicTemplateEmailsFolderNameSelector = () => createSelector(
    [selectCurrentTemplateEmailsFolder],
    currentFolder => currentFolder.name
);
export const dynamicTemplateEmailsMasterNameSelector = () => createSelector(
    [selectCurrentTemplateEmailsMaster],
    currentMaster => currentMaster.template_name
);

export const dynamicConsentNameSelector = () => createSelector(
    [selectConsentCategory],
    consent => consent.consent_category_name
);

export const dynamicProviderNameSelector = () => createSelector(
    [selectCurrentProvider],
    currentProvider => currentProvider.provider_name
);