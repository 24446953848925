import React, { useEffect, useMemo } from 'react';
import { eq, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { selectActionTemplate } from 'store/actions/scenariosActions.action';
import { SectionHeaderWrapper } from 'common/Headers/SectionHeader/SectionHeaderWrapper';
import { useTranslation } from 'react-i18next';
import { findScenariosAction } from 'store/selectors/scenariosActions.selectors';
import { TemplatesListLayout } from 'layouts/_TemplatesListLayout/TemplatesListLayout';
import { CreateTemplateButton } from 'components/Buttons/CreateTemplateButton/CreateTemplateButton';
import { NcLoader } from 'common/_NcLoader/NcLoader';
import { useLoader } from 'hooks/useLoader';
import { useTemplates } from 'hooks/TemplatesSetup/useTemplates';
import { CustomModal } from 'common/_CustomModal/CustomModal';
import styles from 'pages/Templates/Templates.module.css';
import { CreateEmailTemplateModal } from 'common/_CustomModal/CreateEmailTemplateModal/CreateEmailTemplateModal';
import { SelectMasterTemplateModal } from 'common/_CustomModal/SelectMasterTemplateModal/SelectMasterTemplateModal';
import { useModal } from 'hooks/CustomModals/useModal';
import { useEmailMasters } from 'hooks/TemplatesSetup/useEmailMasters';
import { createTemplate } from 'store/actions/templates.action';
import { NumberUse } from 'lib/NumberUse';
import { AddNewRoundButton } from 'common/_Buttons/_AddNewRoundButton/AddNewRoundButton';

const emailTemplateJinjaFields = [
    { jinja_field_name: 'jinja_body', value: '' },
    { jinja_field_name: 'jinja_css', value: '' },
    { jinja_field_name: 'jinja_sender_email', value: '' },
    { jinja_field_name: 'jinja_sender_name', value: '' },
    { jinja_field_name: 'jinja_msg_topic', value: '' },
    { jinja_field_name: 'jinja_pre_header', value: '' },
    { jinja_field_name: 'jinja_label_offer', value: '' },
    { jinja_field_name: 'jinja_promo_code', value: '' },
    { jinja_field_name: 'jinja_end_promo_date', value: '' },
    { jinja_field_name: 'jinja_logo', value: '' },
    { jinja_field_name: 'jinja_banner', value: '' },
    { jinja_field_name: 'jinja_utm_content', value: '' },
    { jinja_field_name: 'jinja_utm_source', value: '' },
    { jinja_field_name: 'jinja_utm_medium', value: '' },
    { jinja_field_name: 'jinja_utm_term', value: '' },
    { jinja_field_name: 'jinja_utm_campaign', value: '' },
];

const actionTypes = {
    SendEmail: 'email',
    SendSms: 'sms',
    SendViber: 'viber',
    WebsiteBuilder: 'website',
    SendMobPush: 'mobpushnotify',
    SendWebPush: 'webpushnotify'
};

export const ActionTemplatesPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { goal, id } = useParams();
    const { t: translator } = useTranslation();

    const { id: actionId, type: actionType } = useSelector(store => store.scenariosActions.actionSidebar);

    const action = useSelector(store => findScenariosAction(store, actionId));

    const { templatesList, CardTemplate, type, isLoadingTemplates } = useTemplates(actionTypes[actionType]);

    const loader = useLoader(isLoadingTemplates, [isLoadingTemplates, type]);

    const { masters } = useEmailMasters();

    const {
        ref: createEmailTemplateModalRef,
        isToggle: isToggleCreateEmailTemplateModal,
        setShowModal: setShowCreateEmailTemplateModal,
        setHideModal: setHideCreateEmailTemplateModal
    } = useModal({
        isNeedOutsideAlerter: true,
    });

    const {
        ref: selectMasterTemplateModalRef,
        isToggle: isToggleMasterTemplateModal,
        setShowModal: setShowMasterTemplateModal,
        setHideModal: setHideMasterTemplateModal
    } = useModal({
        isNeedOutsideAlerter: true,
    });

    const closeSelectTemplatePage = () => navigate(`/communications/goal/${goal}/${id}`);

    const selectTemplate = template => {
        return () => {
            return dispatch(selectActionTemplate(actionId, template.template_unique_id)).then(() => closeSelectTemplatePage());
        }
    };

    const getActionSelectedTemplate = () => {
        return () => {
            if (action) {
                const { settings: { templateUniqueId = '' }} = action;

                return templateUniqueId;
            }
        }
    };
    const onClickCreateEmailTemplateButton = () => setShowCreateEmailTemplateModal();
    const afterSuccessCreateEmailDefaultTemplate = ({ template: { template_unique_id } }) => {
        return navigate(`/all-templates/${type}/${template_unique_id}`);
    };

    const createDefaultTemplateName = () => {
        return `NewTemplate_${type}_` + NumberUse.getRandomInt(1000);
    };

    const onClickDefaultEmailTemplateTypeButton = () => {
        new Promise(resolve => {
            dispatch(createTemplate({
                new_template: {
                    template_name: createDefaultTemplateName(),
                    message_type_name: type,
                    jinja_fields: emailTemplateJinjaFields,
                }
            }, afterSuccessCreateEmailDefaultTemplate))

            resolve()
        }).then(() => setHideCreateEmailTemplateModal())
    };

    const onClickMasterTemplateTypeButton = () => {
        new Promise(resolve => {
            setHideCreateEmailTemplateModal()

            resolve();
        }).then(() => setShowMasterTemplateModal())
    };

    const afterSuccessCreateSelectedMasterTemplate = ({ template: { template_unique_id } }) => {
        return navigate(`/all-templates/${type}/master-based/${template_unique_id}`);
    };
    const onClickAcceptSelectMasterTemplateModal = data => {
        return () => new Promise(resolve => {
            dispatch(createTemplate({
                new_template: {
                    template_master_id: data.id,
                    template_name: createDefaultTemplateName(),
                    message_type_name: type,
                    jinja_fields: emailTemplateJinjaFields,
                }
            }, afterSuccessCreateSelectedMasterTemplate))

            resolve()
        }).then(() => setHideMasterTemplateModal())
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const selectedTemplate = useMemo(getActionSelectedTemplate(), [action]);

    useEffect(() => {
        if (!actionId) {
            navigate(`/communications/goal/${goal}/${id}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <SectionHeaderWrapper
                sectionHeaderTitle={action?.title}
                closeButtonCallback={closeSelectTemplatePage}
            />

            <TemplatesListLayout
                creativesType={type}
                sectionTitle={translator('default:ActionTemplatePages')}
                newTemplateButton={
                    eq(type, 'Email')
                        ? <AddNewRoundButton
                            actionLabel={translator('default:AddButtonLabel')}
                            onClick={onClickCreateEmailTemplateButton}
                            isFixedDisplayPosition
                        />
                        : <CreateTemplateButton typeMessage={type}/>
                }
                creativesListArray={
                    loader
                        ? <NcLoader/>
                        : map(templatesList, (item, key) => (
                            <React.Fragment key={key}>
                                {action &&
                                    <CardTemplate
                                        templateId={selectedTemplate}
                                        template={item}
                                        templateType={type}
                                        isShowChainButton={false}
                                        redirectPath='edit-template'
                                        onClick={selectTemplate(item)}
                                    />
                                }
                            </React.Fragment>
                ))}
            />

            {isToggleCreateEmailTemplateModal &&
                <CustomModal
                    componentRef={createEmailTemplateModalRef}
                    isOpened={isToggleCreateEmailTemplateModal}
                    modalClassName={styles.createEmailTemplateModal}
                    children={
                        <CreateEmailTemplateModal
                            title={translator('popUpsSystemMessages:CreateEmailTemplateModalTitle')}
                            codeEditorTitle={translator('popUpsSystemMessages:CreateEmailTemplateModalCodeEditorTitle')}
                            codeEditorDesc={translator('popUpsSystemMessages:CreateEmailTemplateModalCodeEditorDesc')}
                            masterTitle={translator('popUpsSystemMessages:CreateEmailTemplateModalMasterTitle')}
                            masterDesc={translator('popUpsSystemMessages:CreateEmailTemplateModalMasterDesc')}
                            onClickDefaultTemplateButton={onClickDefaultEmailTemplateTypeButton}
                            onClickMasterTemplateButton={onClickMasterTemplateTypeButton}
                            cancelButtonTitle={translator('default:CancelButtonTitle')}
                            onClickCancel={setHideCreateEmailTemplateModal}
                        />
                    }
                />
            }

            {isToggleMasterTemplateModal &&
                <CustomModal
                    componentRef={selectMasterTemplateModalRef}
                    isOpened={isToggleMasterTemplateModal}
                    modalClassName={styles.createEmailTemplateModal}
                    children={
                        <SelectMasterTemplateModal
                            title={translator('popUpsSystemMessages:SelectMasterTemplateModalTitle')}
                            desc={translator('popUpsSystemMessages:SelectMasterTemplateModalDesc')}
                            masters={masters}
                            cancelButtonTitle={translator('default:CancelButtonTitle')}
                            acceptButtonTitle={translator('default:AcceptButtonTitle')}
                            onClickCancel={setHideMasterTemplateModal}
                            onClickAccept={onClickAcceptSelectMasterTemplateModal}
                        />
                    }
                />
            }
        </>
    );
};
