import React, { useEffect, useState } from 'react';
import styles from './Processing.module.css';
import { Logo } from 'shopify/components/Logo/Logo';
import { Title } from 'shopify/components/Title/Title';
import { TextSecondary } from 'shopify/components/TextSecondary/TextSecondary';
import { Slider } from 'shopify/structures/Slider/Slider';
import { ProgressBar } from 'shopify/components/ProgressBar/ProgressBar';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CommonMethods } from 'lib/CommonMethods';
import { useSelector } from 'react-redux';

const Processing = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { isAuthenticated } = useSelector(store => store.auth);

    const [integrationProcess, setIntegrationProcess] = useState(0);

    const fetchRetailerInfo = () => {
        const urlParams = new URLSearchParams(location.search);

        const shop = urlParams.get('shop');

        if (shop) {
            axios
                .request({
                    url: CommonMethods.replaceUrlEndpoint(`retailer_info?shop=${shop}`),
                    method: 'GET',
                    config: { headers: { 'Content-Type': 'application/json' } },
                })
                .then((response) => {
                    setIntegrationProcess(response.data.integration_progress)

                    if (response.data.integration_progress === 100) {
                        navigate('/');
                    }
                })
                .catch((error) => {
                    console.error('Failed to fetch retailer info:', error);
                });
        }
    };

    useEffect(() => {
        // Первоначальный вызов
        fetchRetailerInfo();

        // Устанавливаем интервал вызова
        const intervalId = setInterval(fetchRetailerInfo, 30000); // 30 секунд

        // Очистка интервала при размонтировании
        return () => clearInterval(intervalId);
    }, [location.search]); //eslint-disable-line

    return (
        <div className={styles.container}>
            {!isAuthenticated &&
                <Logo/>
            }
            
            <Title
                text='Just a little more to go. We are preparing the platform for the first launch'
                className={styles.title}
            />
            <TextSecondary
                text='Meanwhile learn more about our platform while we prepare everything'
            />

            <div className={styles.content}>
                <Slider/>

                <ProgressBar
                    value={integrationProcess}
                />
            </div>
        </div>
    )
}

export default Processing;